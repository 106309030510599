import type { OptionalBreakpointKey, RequiredBreakpointKey } from "shared/lib/styles";

export const JWTExpiryThreshold = 1800; // Seconds
export const JWTRefreshSWRKey = "refresh-jwt";

export const DefaultHeaderSwapThreshold = 150;

export const HeaderArticleSpeechContainerElementID = "header-article-speech-container";

export const IconMenuResponsiveVariants = {
  desktopUp: "hidden",
  largeDesktopUp: "hidden",
  mobileUp: "show",
  tabletUp: "show",
} as const;

export const Breakpoints: Array<OptionalBreakpointKey | RequiredBreakpointKey> = [
  "desktopUp",
  "largeDesktopUp",
  "mediumDesktopUp",
  "mobileUp",
  "tabletUp",
];
