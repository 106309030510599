import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontRoboto, theme } from "@product/scmp-sdk";

import ArrowRightIcon from "./arrow-right.svg";
import CloseIcon from "./icon-close.svg";

export const StyledArrowRightIcon = styled(ArrowRightIcon)``;
export const StyledCloseIcon = styled(CloseIcon)``;

export const Text = styled.span`
  flex: 1 1 auto;

  color: #4585ff;
`;

export const RegularContainer = styled.div`
  display: inline-flex;
  gap: 8px;
  align-items: center;

  inline-size: 204px;
  block-size: 44px;
  padding-block: 10px;
  padding-inline: 12px 16px;

  border: 1px #4585ff solid;
  border-radius: 4px;

  background-color: #ffffff;

  cursor: pointer;

  ${Text} {
    font-weight: 700;
    font-size: 14px;
    font-family: ${fontRoboto};
    line-height: 17px;
  }
`;

type StyledMinifyContainerProps = {
  $withMinifyText: boolean;
};

export const MinifyContainer = styled.div<StyledMinifyContainerProps>`
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  block-size: 44px;

  cursor: pointer;

  ${Text} {
    font-weight: 700;
    font-size: 12px;
    font-family: ${fontRoboto};
    line-height: 14px;
  }

  ${theme.breakpoints.up("tablet")} {
    flex-direction: column;
    justify-content: center;

    inline-size: 44px;
    block-size: 44px;

    ${props => {
      if (props.$withMinifyText) {
        return css`
          padding-block-end: 8px;
        `;
      }
    }}
  }
`;

type MainContainerProps = {
  $isGreyedOut: boolean;
};

export const MainContainer = styled.div<MainContainerProps>`
  ${props =>
    props.$isGreyedOut &&
    css`
      ${Text} {
        color: #666666;
      }

      ${RegularContainer} {
        border-color: #666666;
      }
    `}
`;

export const PausedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 204px;
  block-size: 44px;
  padding-block: 10px;
  padding-inline: 12px 16px;

  border: 1px #4585ff solid;
  border-radius: 4px;
`;
